import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './main.scss'
import { mudarApp } from '../../app/appSlice';
import { selectTodosDadosApps } from '../../app/appSelectors';

function Main() {
	const { t } = useTranslation('main');
	const dispatch = useDispatch();
	let dadosApps = useSelector(selectTodosDadosApps);
	let inicio = useRef(true);

	useEffect(() => {
		if (!inicio.current) return;
		inicio.current = false;		
		dispatch(mudarApp('main'));
	});

	return (
		<div className="home">
			<h2>{t('welcome')}</h2>
			<h3 className="idea">{t('idea')}</h3>
			<br />
			<h3>{t('choose')}</h3>

			<ul>
				{Object.entries(dadosApps)
					.filter(app => app[1].listar)
					.map(app =>
						<li key={app[0]}><Link to={"/" + app[0]}>{t(app[1].title, {ns: 'app'})}</Link> - {t(app[1].subtitle, {ns: 'app'})}</li>
					)}
			</ul>
		</div>
	)
}

export default Main;