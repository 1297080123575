import React from 'react'
import { selectAppAtual } from '../../app/appSelectors';
import { useSelector } from 'react-redux';

import { AboutMain } from '../main/about';
import { AboutVimKing } from '../vimKing/about';
import { AboutPaper } from '../paper/about';
import { AboutCubeTeacher } from '../cubeTeacher/about';

function About() {
	let app = useSelector(selectAppAtual);
	
	return (
		<section>			
			{ app === 'main' && <AboutMain /> }
			{ app === 'vimking' && <AboutVimKing /> }
			{ app === 'paper' && <AboutPaper /> }
			{ app === 'cubeteacher' && <AboutCubeTeacher /> }
		</section>
	)
}

export default About;