import { createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit';
import appInitialState from './appInitialState.json';
import axios from 'axios';
import { ClientJS } from 'clientjs';

import { checkIsIPV4 } from './lib/genericHelper';
import { BE_HOST } from './lib/constants';

export const createAsyncTrunkWithBackendErrorSupport = async (url, impl, rejectWithValue) => {
	try {
		const response = await impl(BE_HOST + url);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
}
export const signinAndRefreshAsyncThunk = (url, rejectWithValue) => {
	return createAsyncTrunkWithBackendErrorSupport(url,
		async (url) => {
			let client = new ClientJS();
			let ip;
			try {
				let response = await axios.get('https://api.ipify.org/?format=json');
				ip = response.data.ip;
			} catch (e) {
				ip = '0.0.0.0';
			}
			if (!checkIsIPV4(ip))
				ip = '0.0.0.0';

			const info = {
				fingerprint: client.getFingerprint(), browser: client.getBrowser(), os: client.getOS() + " " + client.getOSVersion(),
				device: client.getDevice(), deviceType: client.getDeviceType(), isMobile: client.isMobile(), resolution: client.getCurrentResolution(),
				ip
			};
			return await axios.post(url, info);
		},
		(rejectWithValue));
}

export const signin = createAsyncThunk('signin', async (_, { getState, rejectWithValue }) => {
	const state = await getState();
	if (state.app.token) 
		return { accessToken: state.app.token };
	 else {
		let url = 'auth/signin';
		return await signinAndRefreshAsyncThunk(url, rejectWithValue);
	}
})


const appSlice = createSlice({
	name: 'app',
	initialState: appInitialState,
	reducers: {
		mudarApp: (state, action) => {
			state.atual = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addMatcher(isAnyOf(signin.fulfilled), (state, action) => {
				state.token = action.payload.accessToken;
			})
	}
})

export const { mudarApp } = appSlice.actions

export default appSlice.reducer