import React from 'react'
import { useTranslation } from 'react-i18next';

export const AboutPaper = () => {
	const { t } = useTranslation('paper');

	return (
		<div className="about">
			<h1>{t('about.title')}</h1>

			<p>{t('about.subtitle')}</p>
		</div>
	)
}