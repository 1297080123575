import React, { useEffect, useRef } from 'react'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import { useTranslation, Trans } from 'react-i18next';

import { mudarApp, signin } from '../../app/appSlice';
import { selecionarComando, deselecionarComando } from '../vimKing/vimkingSlice';
import {
	selectCnt,
	selectTodosComandos
} from '../../features/vimKing/vimkingSelectors';
import './vimKing.scss'
import { hash } from '../../app/lib/genericHelper';
import imgCrown from '../../img/crown.png';
import imgCrownBw from '../../img/crownbw.png';
import imgCrownKing from '../../img/crownKing.png';
import HorizontalAd from '../../app/ads/horizontalAd'

function VimKing() {
	const dispatch = useDispatch();
	let comandos = useSelector(selectTodosComandos);
	let numTotalComandos = comandos.reduce((acc, modo) => acc + modo.commands.length, 0);
	let cnt = useSelector(selectCnt);
	const { t } = useTranslation('vimking');

	let inicio = useRef(true);

	let tabIndex = 0;
	let seqiframe = 0;
	const TAM_PAGINA = 20;

	const OnSelecionarComando = (event, modo, codigo, selecionado) => {
		event.preventDefault();
		let acao = selecionado ? deselecionarComando : selecionarComando;
		dispatch(acao({ modo, codigo }));

		event.target.focus();
	}

	const percCompleta = () => {
		let perc = Math.round(cnt / numTotalComandos * 10000) / 100;
		return perc > 100 ? 100 : perc;

	}

	const msgPercKing = () => {
		return percCompleta() >= 100 ? t('king') : t('not-king', { perc: percCompleta() });
	}

	const selecionarProximaLinha = (tr, inverter = false) => {
		do {
			tr = inverter ? tr.previousSibling : tr.nextSibling;
		} while (!tr.getAttribute('tabIndex'));
		tr.focus();
		centrarNoComandoAtivo();
	}

	const centrarNoComandoAtivo = () => {
		let y = document.activeElement.offsetTop;// + (window.innerHeight);
		window.scrollTo({ top: y, left: 0, behavior: 'smooth' });
	}

	const selecionarPrimeiraLinha = () => {
		document.getElementsByTagName('tr')[2].focus();
		centrarNoComandoAtivo();
	}

	const selecionarUltimaLinha = () => {
		document.getElementsByTagName('tr')[document.getElementsByTagName('tr').length - 1].focus();
		centrarNoComandoAtivo();
	}

	const selecionarProximaPagina = () => {
		let tr = document.activeElement;
		for (let i = 1; i <= TAM_PAGINA; i++)
			do {
				tr = tr.nextSibling;
			} while (!tr.getAttribute('tabIndex'));

		tr.focus();
		centrarNoComandoAtivo();
	}

	const selecionarPaginaAnterior = () => {
		let tr = document.activeElement;
		for (let i = 1; i <= TAM_PAGINA; i++)
			do {
				tr = tr.previousSibling;
			} while (!tr.getAttribute('tabIndex'));

		tr.focus();
		centrarNoComandoAtivo();
	}

	const onLineKeyUp = (event, modo, comando) => {
		event.preventDefault();
		if (!event.key) return;
		document.getElementsByClassName('lista')[0].classList.add('nocursor');


		switch (event.key) {
			case 'ArrowUp':
			case 'k':
				selecionarProximaLinha(event.target, true)
				break;
			case 'ArrowDown':
			case 'Tab':
			case 'j':
				selecionarProximaLinha(event.target)
				break;
			case 'Enter':
			case 'Space':
			case ' ':
				OnSelecionarComando(event, modo.modeIndex, comando.hash, comando.selecionado);
				break;
			case 'Home':
				selecionarPrimeiraLinha();
				break;
			case 'End':
				selecionarUltimaLinha();
				break;
			case 'PageUp':
				selecionarPaginaAnterior();
				break;
			case 'PageDown':
				selecionarProximaPagina();
				break;
			default:
			//nop
		}
	}

	useEffect(() => {
		if (!inicio.current) return;
		inicio.current = false;
		dispatch(mudarApp('vimking'));
		dispatch(signin());
	})

	useEffect(() => {
		document.getElementsByClassName('linhaComando')[0].focus();
		const onKeyDown = (e) => {
			if ((e.keyCode === 32 && e.target.tagName === 'TR') ||
				(['PageUp', 'PageDown'].indexOf(e.key) >= 0)
			)
				e.preventDefault();
		}

		window.addEventListener('keydown', onKeyDown);

		const noCursorEventListener = (e) => {
			let lista = document.getElementsByClassName('lista');
			if (lista && lista[0]) lista[0].classList.remove('nocursor');
		}

		window.addEventListener('mousemove', noCursorEventListener);

		return () => {
			window.removeEventListener('mousemove', noCursorEventListener);
			window.removeEventListener('keydown', onKeyDown);
		}
	})


	return (
		<div className="vimKingApp">
			<h1>{t('apps.vimking.title', { ns: 'app' })} - {t('apps.vimking.subtitle', { ns: 'app' })}</h1>

			<p className="instrucoes">{t('instructions')}</p>
			<p className="instrucoes2">{t('instructions2')}</p>

			<div className="resultados" style={{ display: `${cnt > 0 ? 'block' : 'none'}` }}>
				<div>
					<Trans i18nKey="youknow" ns="vimking" cnt={cnt} numTotalComandos={numTotalComandos}>
						You know {{ cnt }}/{{ numTotalComandos }} commands.
					</Trans>
				</div>
				<div>{msgPercKing()}</div>
				<img src={imgCrownBw} className="img-bottom" alt="Black and white crown" />
				<img src={imgCrown} className="img-top" style={{ clip: `rect(0, ${240 * percCompleta() / 100}px, 177px, 0)` }} alt="Color crown" />
				<img src={imgCrownKing} className="img-king" style={{ display: `${cnt >= 1273 ? 'block' : 'none'}` }} alt="King crown" />
			</div>

			<table className="lista">
				<tbody>
					{
						comandos
							.map(modo =>
								<React.Fragment key={hash(modo.mode + modo.modeIndex)}>
									<tr style={{ display: `${seqiframe ? 'table-row' : 'none'}` }} className="horAd"><td colSpan="2"><HorizontalAd seq={seqiframe++} /></td></tr>
									<tr className="sectionName"><td colSpan="2">{modo.mode}</td></tr>
									{modo.commands
										.map(comando =>
											<tr onKeyUp={(event) => onLineKeyUp(event, modo, comando)} tabIndex={tabIndex++} key={comando.hash} className={`linhaComando${comando.selecionado ? ' green' : ''}`} onClick={(event) => OnSelecionarComando(event, modo.modeIndex, comando.hash, comando.selecionado)}>
												<td className="comando">{comando.teclas}</td><td className="descricao">{comando.descricao}</td>
											</tr>
										)
									}

								</React.Fragment>
							)
					}
				</tbody>
			</table>
		</div>
	)
}

export default VimKing;