import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import { signin, mudarApp } from '../../app/appSlice';
import { refreshToken, fetchNewHash, fetchPaperByHash, savePaper, updateContent } from './paperSlice';
import { FE_HOST } from '../../app/lib/constants';
import './paper.scss';

const MAX_LENGTH = 65536;

class Paper extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hash: '',
			content: '',
			url: '',
			saveButtonText: this.props.t('save'),
			refreshingToken: false
		}
	}
	
	  
	onContentChanged = (e) => {
		this.props.updateContent(e.target.value);
		this.setState({ content: e.target.value });
	}

	dispatchAndCheckExpiration = (action, args = []) => {
		if (!Array.isArray(args))
			args = [args]
		action(...args).then(payload => {
			if (payload.type.indexOf('rejected') >= 0)
				return;
			let { payload: { statusCode } } = payload;
			if (statusCode === 406) {
				this.setState({refreshingToken: true});
				this.props.refreshToken()
					.then(() => action(...args)
						.then(() => this.setState({ refreshingToken: false})))
			}
		})
	}

	newPaper = (e) => {
		this.dispatchAndCheckExpiration(this.props.fetchNewHash);
		this.setState({ content: '' });
	}

	save = () => {
		if (!this.state.content) return;
		this.dispatchAndCheckExpiration(this.props.savePaper, { hash: this.props.hash, content: this.props.content })
	}

	paperUrl = () => FE_HOST + 'paper/' + this.props.hash;

	componentDidMount = () => {
		this.props.mudarApp('paper');
		this.dispatchAndCheckExpiration(this.props.signin);
		this.props.i18n.on('languageChanged', (_) => {
			this.setState({ 
				saveButtonText: this.props.content.length <= MAX_LENGTH ? this.props.t("save") : this.props.t("big")
			})			
		})
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.hash !== prevProps.hash) 
			window.history.replaceState(null, "Paper", "/paper/" + this.props.hash);

		if (this.props.token !== prevProps.token && !prevProps.token) {
			let url = new URL(window.location.href);
			let hash_ = url ? url.pathname.split('/').filter(e => e && e !== 'paper')[0] : null;
			if (!hash_)
				this.dispatchAndCheckExpiration(this.props.fetchNewHash);
			else
				this.dispatchAndCheckExpiration(this.props.fetchPaperByHash, hash_);
		}

		if (this.props.content !== prevProps.content) {
			this.setState({ 
				content: this.props.content,
				saveButtonText: this.props.content.length <= MAX_LENGTH ? this.props.t("save") : this.props.t("big")
			})
		}
	}
		
	render() {
		const { t } = this.props;
		
		return (
			<div className="paper">
				<div className="container">
					<div>
						<h2 className="title">{t('apps.paper.title', { ns: 'app'})} - {t('apps.paper.subtitle', { ns: 'app'})} </h2>
						<span>{t('grablink')} <a href={this.paperUrl()}>{this.paperUrl()}</a></span>
					</div>
					<div style={{ color:  this.state.content.length > MAX_LENGTH ? 'red' : 'white', marginTop: '2em' }}>
						{t("available")} { MAX_LENGTH - this.state.content.length }
					</div>
					<div id="divAcoes">
						<input type="button" id="btnNew" onClick={this.newPaper} value={t("new")} />
						<input type="button" id="btnSave" disabled={ this.state.content.length > MAX_LENGTH } onClick={this.save} value={this.state.saveButtonText} />
					</div>
				</div>
				<textarea
					value={this.state.content}
					onChange={this.onContentChanged}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		hash: state.paper.hash,
		content: state.paper.content,
		token: state.app.token
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	mudarApp, signin, refreshToken, fetchNewHash, fetchPaperByHash, savePaper, updateContent
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('paper')(Paper));

