import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

import { mudarApp } from '../../app/appSlice';
import solutions from './solutions.json';
import { signin } from '../../app/appSlice';
import './cubeTeacher.scss';

function CubeTeacher() {
	const dispatch = useDispatch();

	const catListInitialState = () => {
		let categorias = {};
		Object.keys(solutions).sort().forEach(cat => {
			categorias[cat.split('-')[1]] = {
				nome: cat.split('-')[1].toUpperCase(),
				seq: cat.split('-')[0],
				ativa: true,
				items: solutions[cat]
			}
		})
		return categorias;
	}

	const [catList, setCatList] = useState(catListInitialState());
	const [verSolucao, setVerSolucao] = useState(false);
	const [catAtivas, setCatAtivas] = useState(Object.keys(catList).filter(cat => catList[cat].ativa));
	const { t } = useTranslation('cubeteacher');
	let inicio = useRef(true);

	const handleOnChange = (cat) => {
		const updatedCatList = { ...catList };
		updatedCatList[cat].ativa = !catList[cat].ativa;

		setCatList(updatedCatList);
		setCatAtivas(Object.keys(catList).filter(cat => catList[cat].ativa))
	};

	const escolherPosicao = () => {
		if (!catAtivas.length) return;
		let catEscolhida = catAtivas[Math.round(Math.random() * (catAtivas.length - 1))];
		let items = catList[catEscolhida].items;
		let itemsKeys = Object.keys(items);
		let posicaoEscolhida = itemsKeys[Math.round(Math.random() * (itemsKeys.length - 1))];
		let partes = posicaoEscolhida.split('-');
		let nome = partes[partes.length - 1];
		return { cat: catEscolhida, posicao: { nome, solucao: items[posicaoEscolhida], img: `img/cubeteacher/${catEscolhida}/${posicaoEscolhida.replace(' ', '_')}.png` } };
	}

	const [posicaoAtiva, setPosicaoAtiva] = useState(escolherPosicao());

	const OnBtnClick = () => {
		if (!catAtivas.length && verSolucao) return;
		if (verSolucao) {
			setVerSolucao(false);
			setPosicaoAtiva(escolherPosicao());
		} else
			setVerSolucao(true);
	}

	useEffect(() => {
		if (!inicio.current) return;
		inicio.current = false;
				
		dispatch(mudarApp('cubeteacher'));
		dispatch(signin());
	});
		
		
	useEffect(() => {
		const keyboardEventListener = (e) => {
			if (e.keyCode === 32) {
				e.preventDefault();
				OnBtnClick();
			}
		}

		const touchEventListener = (e) => {
			OnBtnClick();
		}
	
		document.body.addEventListener('keydown', keyboardEventListener);
		document.getElementById('imgPosicao').addEventListener('click', touchEventListener);
		return () => {
			document.body.removeEventListener('keydown', keyboardEventListener);
			let imgPosicao = document.getElementById('imgPosicao');
			if (imgPosicao)
				imgPosicao.removeEventListener('click', touchEventListener);
		}
	});

	return (
		<div className="cubeTeacherApp">
			<h1>{t('apps.cubeteacher.title', { ns: 'app'})} - {t('apps.cubeteacher.subtitle', { ns: 'app'})} </h1>
			<div className="container">
				<div>
					{
						Object.keys(catList)
							.map(tipo =>
								<div key={tipo} className="checkGroup">
									<input className="form-check-input checkboxElement" type="checkbox"
										role="switch" id="flexSwitchCheckDefault" checked={catList[tipo].ativa}
										onChange={() => handleOnChange(tipo)} />
									{catList[tipo].nome}
								</div>
							)
					}
				</div>
				<div style={{ 'textAlign': 'center' }}>
					<img id="imgPosicao" className="escolhida" src={posicaoAtiva.posicao.img} alt={t('imgAlt')} /><br />
					<div>{posicaoAtiva.cat.toUpperCase()} {t('position')} {posicaoAtiva.posicao.nome} </div>
					<div>{t('solution')}</div>
					<div id="solucao">{verSolucao ? posicaoAtiva.posicao.solucao : '###############'}</div>
					<button onClick={() => OnBtnClick()} > {verSolucao ? t('next') : t('see')}	</button>
				</div>
			</div>

		</div>
	)
}

export default CubeTeacher;