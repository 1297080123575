import React from 'react'

function NavBar() {
	return (
		<div className="footer">
			<div className="message">
			<br />
			<br />
			<br />
				<span>Utilities.cc by Nelson Teixeira. Contact: nelson777@gmail.com</span>
				<span><a href="https://www.freepik.com/vectors/background">Utilities.cc main banner created by macrovector - www.freepik.com</a></span>
				<span><a href="https://www.freepik.com/vectors/background">No ads pattern created by visnezh - www.freepik.com</a></span>
				<span>This product includes GeoLite2 data created by MaxMind, available from <a href="https://www.maxmind.com">https://www.maxmind.com</a></span>.
				
			<br />
			<br />
			<br />
			</div>
		</div>
	)
}

export default NavBar;