export const isProd = window.location.hostname === 'utilities.cc' ? 'true' : false;

export const getUtilitiesApp = (routerLocation) => {
	let location = routerLocation;
	location = location.pathname.split('/')[1];
	let res = (location === 'about') ? '' : location;
	return res;
}

export const hash = s => { for (var i = 0, h = 9; i < s.length;)h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9); return h ^ (h >>> 9); }

export const checkIsIPV4 = (ip) =>   
  (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
  