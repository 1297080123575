import React from 'react'
import './about.scss'
import imgReact from '../../img/react.svg';
import imgRedux from '../../img/redux.svg';
import imgJq from '../../img/jq.png';
import imgVIM from '../../img/vim-seeklogo.com.svg';
import imgNestJs from '../../img/nestjs.svg';
import imgPg from '../../img/pg.svg';
import imgDocker from '../../img/docker.png';
import { useTranslation, Trans } from 'react-i18next';

export const AboutMain = () => {
	const { t } = useTranslation('main');
 
	return (
		<div className="about">
			<h1>{t("about.title")}</h1>
	
			<p>{t('about.description')}</p>
	
			<p>{t("about.version")} {process.env.REACT_APP_VERSION} - {process.env.REACT_APP_ENV}</p>
			
			<p>
				<Trans i18nKey="about.created" ns="main">
					Utilities.cc was created using <a href="https://reactjs.org/"><img src={imgReact} width="20" alt="ReactJs"/>React</a>, <a href="https://redux.js.org/"><img src={imgRedux} width="25" alt="Redux"/>Redux</a>, <a href="https://nestjs.com/"><img src={imgNestJs} width="25" alt="NestJS"/>NestJS</a>, <a href="https://postgresql.org/"><img src={imgPg} width="25" alt="PostgreSQL"/>PostgreSQL</a>, <a href="https://docker.com"><img src={imgDocker} width="30" alt="Docker"/>Docker</a>, a help from <a href="https://stedolan.github.io/jq/"><img src={imgJq} width="35" alt="Jq"/></a> and of course <a href="https://www.vim.org/"><img src={imgVIM} width="25" alt="VIM" />VIM</a><br />
				</Trans>
				{t("about.developer")}
			</p>
		</div>
	)
}