import { createSlice } from '@reduxjs/toolkit';

import vimkingInitialState from './vimkingInitialState.json';
import { hash } from '../../app/lib/genericHelper';

const vimkingSlice = createSlice({
	name: 'vimking',
	initialState: {
		cnt: 0,
		lista: vimkingInitialState.map(modo => {
			modo.commands = modo.commands.map(comando => {
				comando.hash = hash(comando.join());
				comando.code = comando[0];
				comando.teclas = comando[1];
				comando.descricao = comando[2];
				delete comando[0];
				delete comando[1];
				delete comando[2];
				return comando;
			})
			return modo;
		})
	},
	reducers: {
		selecionarComando: (state, action) => {
			let { modo, codigo } = action.payload;
			state.cnt += 1;
			let novoState = { ...state };
			let ix;
			for (ix in novoState.lista)
				if (novoState.lista[ix].modeIndex === modo)
					break;
			let ixCommand = novoState.lista[ix].commands.findIndex(c => c.hash === codigo);
			novoState.lista[ix].commands[ixCommand] = { ...novoState.lista[ix].commands[ixCommand], selecionado: true };
			state = novoState;
		},
		deselecionarComando: (state, action) => {
			let { modo, codigo } = action.payload;
			state.cnt -= 1;
			let novoState = { ...state };
			let ix;
			for (ix in novoState.lista)
				if (novoState.lista[ix].modeIndex === modo)
					break;
			let ixCommand = novoState.lista[ix].commands.findIndex(c => c.hash === codigo);
			novoState.lista[ix].commands[ixCommand] = { ...novoState.lista[ix].commands[ixCommand], selecionado: false };
			state = novoState;
		}

	}
})

export const { selecionarComando, deselecionarComando } = vimkingSlice.actions;

export default vimkingSlice.reducer;