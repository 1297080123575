import React, { Suspense } from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import { Navigate } from 'react-router-dom';

import './App.scss'

import Spinner from './components/Spinner'
import Navbar from './app/layout/Navbar'
import Footer from './app/layout/Footer'
import HorizontalAd from './app/ads/horizontalAd'
import VerticalAd from './app/ads/verticalAd'

import Main from './features/main/main'
import VimKing from './features/vimKing/vimKing'
import Paper from './features/paper/paper'
import CubeTeacher from './features/cubeTeacher/cubeTeacher'
import About from './features/about/about'
import Monitor from './features/monitor/monitor'

/*
class DebugRouter extends Router {
	constructor(props) {
		super(props);
		console.log('initial history is: ', JSON.stringify(this.history, null, 2))
		this.history.listen((location, action) => {
			console.log(
				`The current URL is ${location.pathname}${location.search}${location.hash}`
			)
			console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
		});
	}
}
*/

function App() {

	return (
		<Suspense fallback={<Spinner />}>
			<Router>
				<Navbar />
				<div className='divAds'>
					<div className="adsLeft">
						<VerticalAd seq="1" />
						<VerticalAd seq="2" />
						<VerticalAd seq="3" />
					</div>
					<div className='divMiddle' >
						<HorizontalAd seq="1" />
						<div className="App" id='app' >
							<Routes>
								<Route path="/" element={<Main />} />
								<Route path="/:app/about" element={<About />} />
								<Route path="/about" element={<About />} />
								<Route path="/vimking" element={<VimKing />} />
								<Route path="/paper" element={<Paper />} />
								<Route path="/paper/:hash" element={<Paper />} />
								<Route path="/cubeteacher" element={<CubeTeacher />} />
								<Route path="/monitor" element={<Monitor />} />
								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>
						</div>
					</div>
					<div className="adsRight">
						<VerticalAd seq="4" />
						<VerticalAd seq="5" />
						<VerticalAd seq="6" />
					</div>
				</div>
				<Footer />
			</Router>
		</Suspense>
	)
}

export default App
