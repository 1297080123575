import React from 'react'
import { Link } from 'react-router-dom'
import { selectAppAtual, selectDadosApp } from '../appSelectors';
import { useSelector } from 'react-redux';

import './Navbar.scss';
import { useTranslation } from 'react-i18next';

function NavBar() {
	const { t, i18n } = useTranslation('app');
	let app = useSelector(selectAppAtual);
	let dadosApp = useSelector(selectDadosApp(app));

	const onChangeLanguage = (language) => {
		i18n.changeLanguage(language)
	}

	return (
		<nav className={app}>
			<div style={{
			}} className='imgr'></div>
			<div style={{
			}} className='img'></div>
			<div className="appName" style={{
			}}>
				<h1 style={{
				}}>{t(dadosApp.title)}</h1>
				<h3 style={{
				}}>{t(dadosApp.subtitle)}</h3>
			</div>

			<div className="navContent">
				<div className="navLinks">
					<Link to="/">{t("navbar.home", { ns: 'main' })}</Link>
					{app !== 'main' && <Link to={"/" + app}  >{t(dadosApp.title)}</Link>}
					<Link to={(app ? '/' + app : '') + "/about"}>{t("navbar.about", { ns: 'main' })}</Link>
					<select id="language" onChange={(event) => onChangeLanguage(event.target.value)} value={i18n.language} >
						<option value='en'>🇬🇧&emsp;English</option>
						<option value='pt-BR'>🇵🇹&emsp;Português</option>
					</select>
				</div>
			</div>
		</nav>
	)
}

export default NavBar;


//						<option value='es'>🇪🇸&emsp;Español</option>
//						<option value='fr'>🇫🇷&emsp;Français</option>
