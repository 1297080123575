import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import paperInitialState from './paperInitialState.json';
import { signinAndRefreshAsyncThunk, createAsyncTrunkWithBackendErrorSupport } from '../../app/appSlice';

export const refreshToken = createAsyncThunk('refreshToken', async (_, { rejectWithValue }) => {
	let url = 'auth/refreshToken';
	return await signinAndRefreshAsyncThunk(url, rejectWithValue);
})

export const fetchPaperByHash = createAsyncThunk('paper/byHash', async (hash, { rejectWithValue, getState }) => {
	let url = 'paper/byHash/' + hash;
	return await createAsyncTrunkWithBackendErrorSupport(url,
		async (url) => axios.get(url, { headers: { Authorization: `Bearer ${getState().app.token}` } }),
		(hash, rejectWithValue));
})

export const fetchNewHash = createAsyncThunk('paper/selectNewHash', async (_, { rejectWithValue, getState }) => {
	let url = 'paper/newHash';
	return await createAsyncTrunkWithBackendErrorSupport(url,
		async (url) => axios.get(url, { headers: { Authorization: `Bearer ${getState().app.token}` } }),
		(rejectWithValue));
})

export const savePaper = createAsyncThunk('paper/save', async (paper, { getState, rejectWithValue }) => {
	let url = 'paper/save';
	return await createAsyncTrunkWithBackendErrorSupport(url,
		async (url) => await axios.post(url, paper, { headers: { Authorization: `Bearer ${getState().app.token}` } }),
		(rejectWithValue));
})

const paperSlice = createSlice({
	name: 'paper',
	initialState: paperInitialState,
	reducers: {
		updateContent: (state, action) => {
			state.content = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(fetchPaperByHash.fulfilled, (state, action) => {
				state.paper = action.payload.result;
				state.hash = action.payload.result.hash;
				state.content = action.payload.result.content;
			})
			.addCase(fetchNewHash.fulfilled, (state, action) => {
				state.hash = action.payload.result.hash;
				state.content = '';
			})
			.addCase(savePaper.fulfilled, (state, action) => {
				state.paper = action.payload.result;
			})
	}
})

export const { updateContent } = paperSlice.actions

export default paperSlice.reducer;

