import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BE_HOST } from '../../app/lib/constants';
import { hash } from '../../app/lib/genericHelper';

import './monitor.scss';

function Monitor() {
	const [userData, setUserData] = useState({});

	const getCountryNotAvailable = () => {
		let ud = userData['country'] ? userData['country'].find(c => c.code === 'N/A') : null;

		return ud ? ud : { code: 'N/A', cnt: 0 };
	}

	useEffect(() => {
		const getData = async () => {
			let ud = await axios.get(BE_HOST + 'monitor/userData');
			setUserData(ud.data.result);
		}

		getData();
	}, []);

	return (
		<div>
			<div style={{ display: userData.bloqueado ? "block" : "none" }}>
				<h1 className="unauthorized">Unauthorized</h1>
			</div>
			<div style={{ display: userData.bloqueado ? "none" : "block" }}>
				<div className="cards">
					<h1 style={{ textAlign: "center", marginTop: "2em" }}>No country records: <span>{getCountryNotAvailable().cnt}</span></h1>
				</div>

				<div className="cards">
					<h1>User data detail:</h1>
					{
						Object.keys(userData).map((categoria, ix) =>
							<div className="card" key={hash(categoria + ix)} >
								<div className="title">
									{`${categoria[0].toUpperCase()}${categoria.slice(1)}`}
								</div>
								{
									Object.keys(userData[categoria]).map((item, ix) =>
										<div className="item" key={hash(item + ix)}>
											<div className="nome">
												{
													userData[categoria][item].nome
												}
											</div>
											<div className="value">
												{
													userData[categoria][item].cnt
												}
											</div>
										</div>
									)
								}
							</div>)
					}

				</div>
			</div>
		</div>
	)
}

export default Monitor;