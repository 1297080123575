import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

export const AboutVimKing = () => {
	const { t } = useTranslation('vimking');

	return (
		<div className="about vimking">
			<h1>{t("about.title")}</h1>

			<p>{t("about.description")}</p>
			
			<p align="justify">
				<Trans i18nKey="about.myexperience" ns="vimking">
					As a long time VIM user, I wanted to know how much of vim I know. So I got index.txt, removed any non-relevant lines and counted. <br />My Score after 10+ years of VIM and Nunjuncks Gold at VIM Dojo in ShortCutFoo: 140 commands. Aproximately 11% of all VIM commands. Frustrating. :D Can you do better ?
				</Trans>
			</p>
			<p align="justify">{t("about.how")}</p>
		</div>
	)
}