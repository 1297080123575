import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appSlice';
import vimkingReducer from '../features/vimKing/vimkingSlice';
import paperReducer from '../features/paper/paperSlice';

export default configureStore({
	reducer: {
		app: appReducer,
		vimking: vimkingReducer,
		paper: paperReducer
	},
})
